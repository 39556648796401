import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { NSNewsletter, NSToast } from 'aqlrc';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';

class Newsletter extends NSNewsletter {
    handleSubmit = async (e) => {
        e.preventDefault();
        if (!this.state.email) return;
        try {
            await axios.post(`${getAPIUrl()}v2/newsletter/${this.state.email}`, {
                name           : 'DefaultNewsletter',
                isNew          : true,
                optin          : true,
                date_subscribe : new Date(),
            });
            NSToast.success('common:success_subscribe');
            return this.setState({ email: '' });
        } catch (error) {
            NSToast.error('common:error_subscribe');
            return this.setState({ email: '' });
        }
    }

    render() {
        const { email } = this.state;
        const { t, placeholder, button } = this.props;
        // Permet de ne pas voir email_placeholder et subscribe_button dans les inputs pendant le chargement de la apge
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="subscribe__form">
                    <label htmlFor="mail" className="hidden">Email</label>
                    <input
                        type="email"
                        id="mail"
                        name="mail"
                        value={email || ''}
                        onChange={this.handleChange}
                        className="subscribe__field"
                        required
                    />
                    {typeof window !== 'undefined' &&  document.querySelector('.subscribe__form #mail') ? document.querySelector('.subscribe__form #mail').setAttribute('placeholder', t(placeholder)) : ''}
                    <button type="submit" className="subscribe__btn btn btn--medium btn--grey">{t(button)}</button>
                </div>
            </form>
        );
    }
}
export default withI18next(['common'])(Newsletter);
