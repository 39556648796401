import React from 'react';
import { NSToast, logoutUser } from 'aqlrc';
import { Router } from '../routes';

class Disconnect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }
    logout = async () => {
        // Déconnexion de l'utilisateur
        try {
            await logoutUser();
            Router.pushRoute('/');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                NSToast.error(err.response.data.message);
            } else {
                NSToast.error('common:error_occured');
                console.error(err);
            }
        }
    }
    render() {
        return (
            <button type="button" className="btn btn-disconnect" onClick={this.logout}>{this.props.button}</button>
        );
    }
    static defaultProps = {
        button : 'Deconnexion'
    }
}

export default Disconnect;
