import {
    NSLogin, NSToast, getLangPrefix, jwtManager
} from 'aqlrc';
import React from 'react';
import axios from 'axios';
import Head from 'next/head';
import { Router } from '../routes';
import getAPIUrl from '../lib/getAPIUrl';
import { withI18next } from '../lib/withI18n';
import CMS from './CMS';

class Login extends NSLogin {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ...props,
            login     : { email: '', password: '' },
            step      : 0,
            user      : { company: {} },
            address   : {},
            countries : [],
            subUser   : { company: {} }
        };
    }

    loginRequest = async (email, password) => {
        try {
            const { redirect } = this.props;
            const { routerLang } = this.state;
            const jwtToken = await axios.post(`${getAPIUrl()}v2/auth/login`, { username: email, password });
            if (jwtToken === undefined) return;
            axios.defaults.headers.common.Authorization = jwtToken.data.data;
            if (redirect) {
                return Router.pushRoute(redirect);
            }
            Router.pushRoute('home', { lang: routerLang });
        } catch (err) {
            console.error(err);
            if (err.response.data.code === 'LOGIN_DATA_ERROR') {
                NSToast.error('login:error.invalidCredential');
            }
        }
    };

    handleLoginSubmit(event) {
        event.preventDefault();
        const email = document.querySelector('#email_login').value;
        const password = document.querySelector('#password_login').value;
        this.loginRequest(email, password);
    }

    handleRegisterSubmit = (e) => {
        e.preventDefault();
        const { redirect } = this.props;
        const {
            lang, routerLang, subUser, address
        } = this.state;
        const {
            password, passwordConfirm, email, confirmEmail
        } = subUser;
        if (password !== passwordConfirm) {
            NSToast.error('login:error_msg.password_mismatch');
            subUser.password = '';
            subUser.passwordConfirm = '';
            return this.setState({ subUser: this.state.subUser });
        }
        if (email !== confirmEmail) {
            NSToast.error('login:error_msg.mail_mismatch');
            subUser.confirmEmail = '';
            return this.setState({ subUser: this.state.subUser });
        }
        if (subUser.civility === undefined) {
            return NSToast.warn('login:error_msg.choose_civility');
        }
        address.firstname = subUser.firstname;
        address.lastname = subUser.lastname;
        address.companyName = subUser.company.name;
        address.civility = subUser.civility;
        subUser.addresses = [address];
        subUser.delivery_address = 0;
        subUser.billing_address = 0;
        subUser.preferredLanguage = lang;
        subUser.lang = lang;
        this.registerRequest(subUser, async (jwtToken) => {
            axios.defaults.headers.common.Authorization = jwtToken;
            NSToast.success('login:valid_msg.register');
            if (redirect) {
                return Router.pushRoute(redirect);
            }
            return Router.pushRoute('home', { lang: routerLang });
        }, (err) => {
            NSToast.error(err.response.data.message);
            this.setState({ regError: err.response.data.message });
        });
    }

    registerRequest = async (user, onSuccess, onError) => {
        try {
            await axios.put(`${getAPIUrl()}v2/user`, user);
            const jwtToken = await axios.post(`${getAPIUrl()}v2/auth/login`, {
                username : user.email,
                password : user.password,
            });
            axios.defaults.headers.common.Authorization = jwtToken.data.data;
            if (onSuccess) onSuccess(jwtToken.data.data);
        } catch (e) {
            if (onError) onError(e);
        }
    };

    render() {
        const { t } = this.props;
        const {
            emailResetPassword, step, user, address, countries, subUser
        } = this.state;
        return (
            <section className="section-sign-in shell">
                <Head>
                    <title>{t('page.title')}</title>
                    <link rel="stylesheet" href="/static/css/login-register.css" />
                </Head>
                <div className="tabs tabs-only-mobile">
                    <div className="tabs__head visible-xs-block">
                        <nav className="tabs__nav">
                            <ul>
                                <li className="current">
                                    <a href="tab1">{t('login:page.nav.login')}</a>
                                </li>

                                <li>
                                    <a href="tab2">{t('login:page.nav.sign_in')}</a>
                                </li>
                            </ul>
                        </nav>{/* <!-- /.tabs__nav --> */ }
                    </div>{/* <!-- /.tabs__head --> */ }

                    <div className="tabs__body">
                        <div className="tab current" id="tab1">
                            {
                                step === 0 && (
                                    <div className="form form-login form-login--alt">
                                        <form onSubmit={this.handleLoginSubmit}>
                                            <div className="form__head">
                                                <h2>{t('login:page.client_sign_in.title')}</h2>

                                                <h3>{t('login:page.client_sign_in.sub_title')}</h3>

                                                <h6 className="visible-xs-block">{t('login:page.client_sign_in.login_label')}</h6>
                                            </div>{/* <!-- /.form__head --> */ }

                                            <div className="form__body">
                                                <div className="form__row">
                                                    <label htmlFor="email_login" className="form__label hidden">{t('login:page.client_sign_in.password.label')}</label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="field-email" id="email_login" value={user.email} />
                                                        {typeof window !== 'undefined' && document.querySelector('#email_login') ? document.querySelector('#email_login').setAttribute('placeholder', t('login:page.client_sign_in.email.placeholder')) : ''}
                                                    </div>{/* <!-- /.form__controls --> */ }
                                                </div>{/* <!-- /.form__row --> */ }

                                                <div className="form__row">
                                                    <label htmlFor="password_login" className="form__label hidden">{t('login:page.client_sign_in.password.label')}</label>

                                                    <div className="form__controls">
                                                        <input type="password" className="field" name="field-password" id="password_login" value={user.password} placeholder={t('login:page.client_sign_in.password.placeholder')} />
                                                        {typeof window !== 'undefined' && document.querySelector('#password_login') ? document.querySelector('#password_login').setAttribute('placeholder', t('login:page.client_sign_in.password.placeholder')) : ''}
                                                        <div className="form__controls-link text-right">
                                                            <button type="button" onClick={() => this.setState({ step: 2 })} className="nbb">
                                                                {t('login:page.client_sign_in.forgot_password')}
                                                            </button>
                                                        </div>{/* <!-- /.form__controls-link --> */ }
                                                    </div>{/* <!-- /.form__controls --> */ }
                                                </div>{/* <!-- /.form__row --> */ }

                                                <div className="checkbox visible-xs-block">
                                                    <input type="checkbox" name="field-terms1" id="field-terms1" />

                                                    <label htmlFor="field-terms1">{t('login:page.client_sign_in.remember_me')}</label>
                                                </div>{/* <!-- /.checkbox --> */ }
                                            </div>{/* <!-- /.form__body --> */ }

                                            <div className="form__actions text-center">
                                                <button type="submit" className="form__btn btn btn--red">{t('login:page.client_sign_in.button_label')}</button>
                                            </div>{/* <!-- /.form__actions --> */ }
                                        </form>
                                    </div>
                                )
                            }
                            {
                                step === 2 && (
                                    <div className="form form-login form-login--alt">
                                        <form onSubmit={(e) => this.handleResetSubmit(e)}>
                                            <div className="form__head">
                                                <h2>{t('login:page.client_forgot_password.title')}</h2>
                                                <h3>{t('login:page.client_forgot_password.sub_title')}</h3>
                                            </div>{/* <!-- /.form__head --> */ }

                                            <div className="form__body">
                                                <div className="form__row">
                                                    <label htmlFor="email_login_forgot" className="form__label hidden">{t('login:page.client_forgot_password.email.label')}</label>

                                                    <div className="form__controls">
                                                        <input type="text" className="field" name="field-email" id="email_login_forgot" value={emailResetPassword} placeholder="" onChange={(e) => this.handleResetPassword(e)} />
                                                        {
                                                            typeof window !== 'undefined' && document.querySelector('#email_login_forgot')
                                                                ? document.querySelector('#email_login_forgot').setAttribute('placeholder', t('login:page.client_forgot_password.email.placeholder'))
                                                                : ''
                                                        }
                                                    </div>{/* <!-- /.form__controls --> */ }
                                                </div>{/* <!-- /.form__row --> */ }
                                            </div>{/* <!-- /.form__body --> */ }

                                            <div className="form__actions text-center" style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <button type="button" className="form__btn btn btn--red" onClick={() => this.setState({ step: 0 })}>
                                                    {t('login:page.client_forgot_password.return')}
                                                </button>
                                                <button type="submit" className="form__btn btn btn--red">
                                                    {t('login:page.client_forgot_password.button_label')}
                                                </button>
                                            </div>{/* <!-- /.form__actions --> */ }
                                        </form>
                                    </div>
                                )
                            }
                        </div>{/* <!-- /.tab --> */ }

                        <div className="tab" id="tab2">
                            <div className="form form-login">
                                <form
                                    onSubmit={(e) => this.handleRegisterSubmit(e)}
                                >
                                    <div className="form__head">
                                        <h2>{t('login:page.client_sign_up.title')}</h2>

                                        <h3>{t('login:page.client_sign_up.sub_title')}</h3>
                                    </div>{/* <!-- /.form__head --> */ }

                                    <div className="form__body">
                                        <div className="radio-group radio-group--flex  form__row--flex align-right">
                                            <label htmlFor="field-societe" className="label form__label">{t('login:page.client_sign_up.civility.label')}<span>*</span></label>
                                            <ul className="list-radios list-radios--flex">
                                                <li>
                                                    <div className="radio radio--btn">
                                                        <input hidden type="radio" name="civility" id="field-women" value={1} onChange={(e) => this.handleChangeSub(e)} />
                                                        <label htmlFor="field-women">{t('login:page.client_sign_up.civility.female')}</label>
                                                    </div>{/* <!-- /.radio --> */ }
                                                </li>

                                                <li>
                                                    <div className="radio radio--btn">
                                                        <input hidden type="radio" name="civility" value={0} id="field-men" onChange={(e) => this.handleChangeSub(e)} />
                                                        <label htmlFor="field-men">{t('login:page.client_sign_up.civility.male')}</label>
                                                    </div>{/* <!-- /.radio --> */ }
                                                </li>
                                            </ul>{/* <!-- /.list-radios --> */ }
                                        </div>{/* <!-- /.radio-group --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-name" className="form__label">{t('login:page.client_sign_up.lastname.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required type="text" className="field" name="lastname" id="field-name" value={subUser.lastname} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-name-last" className="form__label">{t('login:page.client_sign_up.firstname.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required type="text" className="field" name="firstname" id="field-name-last" value={subUser.firstname} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-address" className="form__label">{t('login:page.client_sign_up.address.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required type="text" className="field" name="address/line1" id="field-address" value={address.line1} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-address-2" className="form__label">{t('login:page.client_sign_up.address_complementary.label')}</label>

                                            <div className="form__controls">
                                                <input type="text" className="field" name="address/line2" id="field-address-2" value={address.line2} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="cols">
                                            <div className="col--small">
                                                <div className="form__row form__row--flex align-right">
                                                    <label htmlFor="field-zip" className="form__label">{t('login:page.client_sign_up.zip.label')}<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input required type="text" className="field" name="address/zipcode" id="field-zip" value={address.zipcode} onChange={(e) => this.handleChangeSub(e)} />
                                                    </div>{/* <!-- /.form__controls --> */ }
                                                </div>{/* <!-- /.form__row --> */ }
                                            </div>{/* <!-- /.col col-1of2 --> */ }

                                            <div className="col--large">
                                                <div className="form__row form__row--flex align-right">
                                                    <label htmlFor="field-city" className="form__label">{t('login:page.client_sign_up.city.label')}<span>*</span></label>

                                                    <div className="form__controls">
                                                        <input required type="text" className="field" name="address/city" id="field-city" value={address.city} onChange={(e) => this.handleChangeSub(e)} />
                                                    </div>{/* <!-- /.form__controls --> */ }
                                                </div>{/* <!-- /.form__row --> */ }
                                            </div>{/* <!-- /.col col-1of2 --> */ }
                                        </div>{/* <!-- /.row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-phone" className="form__label">{t('login:page.client_sign_up.phone_mobile.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required type="tel" className="field" name="address/phone_mobile" id="field-phone" value={address.phone_mobile} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-country" className="form__label">{t('login:page.client_sign_up.country.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <select required type="tel" defaultValue="" className="field" name="address/isoCountryCode" id="field-country" value={address.isoCountryCode} onChange={(e) => this.handleChangeSub(e)}>
                                                    <option value="">--</option>
                                                    {
                                                        countries.map((c) => (<option key={c._id} value={c.code}>{c.name}</option>))
                                                    }
                                                </select>
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-email3" className="form__label">{t('login:page.client_sign_up.email.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required type="email" className="field" name="email" id="field-email3" value={subUser.email} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-email-confirm" className="form__label">{t('login:page.client_sign_up.email_confirm.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required type="email" className="field" name="confirmEmail" id="field-email-confirm" value={subUser.confirmEmail} onChange={(e) => this.handleChangeSub(e)} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-password3" className="form__label">{t('login:page.client_sign_up.password.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required onChange={(e) => this.handleChangeSub(e)} type="password" className="field" name="password" id="field-password3" value={subUser.password} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__row form__row--flex align-right">
                                            <label htmlFor="field-password-confirm3" className="form__label">{t('login:page.client_sign_up.password_confirm.label')}<span>*</span></label>

                                            <div className="form__controls">
                                                <input required onChange={(e) => this.handleChangeSub(e)} type="password" className="field" name="passwordConfirm" id="field-password-confirm3" value={subUser.passwordConfirm} />
                                            </div>{/* <!-- /.form__controls --> */ }
                                        </div>{/* <!-- /.form__row --> */ }

                                        <div className="form__entry form__entry-spaced-left-alt form__entry-no-spacings">
                                            <div className="form__hint form__hint--alt">
                                                <p>{t('login:page.client_sign_up.required_label')}</p>
                                            </div>{/* <!-- /.form__hint --> */ }
                                        </div>{/* <!-- /.form__entry --> */ }
                                    </div>{/* <!-- /.form__body --> */ }

                                    <div className="form__actions text-center">
                                        <button type="submit" className="form__btn btn btn--red">{t('login:page.client_sign_up.button_label')}</button>
                                    </div>{/* <!-- /.form__actions --> */ }

                                    <div className="form__entry">
                                        {
                                            this.props.cmsLegalTxt && <CMS {...this.props} content={this.props.cmsLegalTxt.content || ''} />
                                        }
                                    </div>{/* <!-- /.form__entry --> */ }
                                </form>
                            </div>{/* <!-- /.form form-login --> */ }
                        </div>{/* <!-- /.tab --> */ }
                    </div>{/* <!-- /.tabs__body --> */ }
                </div>{/* <!-- /.tabs --> */ }
                {typeof window !== 'undefined' && document.querySelector('#field-password3') ? document.querySelector('#field-password3').setAttribute('placeholder', t('login:page.client_sign_up.password.placeholder')) : ''}
            </section>
        );
    }
}

export default withI18next(['login'])(Login);
