import React from 'react';
import { Router } from '../routes';

class SwitchMenuMobile extends React.Component {
    componentDidMount = () => {
        if (typeof window !== 'undefined') {
            document.querySelector(`#${this.props['ns-id']}`).onclick = () => {
                document.querySelector(`#${this.props['ns-target-id']}`).classList.toggle('active');
            };

            Router.onRouteChangeStart = (url) => {
                // close menu by removing 'active' class
                document.querySelector(`#${this.props['ns-target-id']}`).classList.remove('active');
            };
        }
    }

    render() {
        return (
            <a href="#" className={this.props['ns-class']} id={this.props['ns-id']}>
                <span></span>
                <span></span>
                <span></span>
            </a>
        );
    }
}

export default SwitchMenuMobile;